import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Cutrone.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Button from "../../../../components/Button";
import Pdp from '../../../../res/cutrone/portrait.png'
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Quik",
  name: "Quik",
  description:
  "Ronald Curtis Cutrone was born in New York City on July 10, 1948, and studied at the School of Visual Arts in Manhattan. He can be seen as the spiritual father of the Street artists. He was Warhol's studio assistant from 1972 to 1980. He was an active member of the New York avant-garde nightlife, when in 1978, a downtown bar called the Mudd Club launched by Steve Mass became a trendy laboratory for the bizarre and the creative. The Mudd gathered all kinds of people, from hard-core punk and post-punks to artists, fashion freaks and Wall Street executives. Mudd Club represented every form of art. Ronnie Cutrone built a cage for this club, which served as a stage for Grace Jones. Art dealers like Tony Shafrazi, Anina Nosei and Mary Boone gave recognition to these multileveled events and had the foresight to discover artists like Cutrone. Numerous other clubs opened in East Village in the early '80s. The Palladium, a megadisco club created in the late '80s, was customized by artistic interventions from Haring, Basquiat and Scharf. From about 1982, after having left Warhol's studio, Ronnie Cutrone concentrated on his own painting. His work shows the characteristics of Pop Art, which draws into the cartoon imagery. He painted cartoon characters like Woody Woodpecker and Donald Duck onto American flags and those of other nations. « I have always sought to create a universal language », Cutrone says, « A visual language by which all who see it may immediately understand its meaning. For me this is the essence of all POP or 'Popular Art'. »",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Ronnie Cutrone.",
  photos: [
    // { src: PastPresent1, name: "Quik" },
    // { src: PastPresent2, name: "Quik" },
    // { src: PastPresent3, name: "Quik" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const Quik = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>RONNIE CUTRONE <h2 style={{paddingLeft: "16px"}}>(Ronald Curtis Cutrone - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1948</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
      </div>
      </div>
      
      
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default Quik;