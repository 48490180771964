// extracted by mini-css-extract-plugin
export var ArtistDescription = "Cutrone-module--ArtistDescription--JIwNw";
export var ArtistInfos = "Cutrone-module--ArtistInfos--Wsxly";
export var ButtonWrapper = "Cutrone-module--ButtonWrapper --yxuw2";
export var CardWrapper = "Cutrone-module--CardWrapper--g7p-1";
export var CarrouselWrapper2 = "Cutrone-module--CarrouselWrapper2--fwRVn";
export var Citations = "Cutrone-module--Citations--pcgVS";
export var DescriptionWrapper = "Cutrone-module--DescriptionWrapper--IDzhM";
export var ImageWrapper = "Cutrone-module--ImageWrapper--Esp2b";
export var LinkWrapper = "Cutrone-module--LinkWrapper--c7wM5";
export var MobileProtrait = "Cutrone-module--MobileProtrait--pxFv-";
export var More = "Cutrone-module--More--PQxDX";
export var MoreButton = "Cutrone-module--MoreButton--MXBls";
export var NameWrapper = "Cutrone-module--NameWrapper--P7nIC";
export var PdpWrapper = "Cutrone-module--PdpWrapper--zARg+";
export var PhotosWrapper = "Cutrone-module--PhotosWrapper--Ja0RI";
export var ProfilWrapper = "Cutrone-module--ProfilWrapper--mu67s";
export var TitleWrapper = "Cutrone-module--TitleWrapper--ihOSK";
export var Wrapper = "Cutrone-module--Wrapper--CldQE";