import * as React from "react";
import Layout from "../../../components/en/Layout";
import RonnieCutrone from "../../../nav/en/Artistes/Ronnie Cutrone";
import { Helmet } from "react-helmet";

const RonnieCutronePage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RONNIE CUTRONE | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,anthonyclarkaone,aonegraffiti,aoneartist,aoneartiste,anthonyclark,bronxartiste,bronxartist,newyorkartist,americanart,aonespraypaint,aoneaerosol,aonepating,aoneavailable,aonedrawing,aonecolorlife,aonebook,aoneretrospectiveexhibition,retrospectiveexhibition,aonegallery,aonegalerie,aoneghostgalerie,aonesharp,aonetoxic,aonebasquiat,aoneagnesB,agnesb,aoneexposition,aoneexhibition,aone,artstudio,artgalleryparis,galerieartparis,artgallerymarseille,galerieartmarseille,artcontemporaingallery,artcontemporaingalerie" />
      </Helmet>
      <RonnieCutrone />
    </Layout>
  );
};

export default RonnieCutronePage;
